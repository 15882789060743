:root {
  --clr-accent: #6565ff;
}

.modal-bg {
  height: 100vh;
  width: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  z-index: 2;
}

.input-modal {
  position: absolute;
  height: 250px;
  background-color: white;
  text-align: center;
  padding: 48px 40px 40px 40px;
  border-radius: 20px;
  z-index: 3;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.inputModal-textfield-flex {
  display: flex;
  margin-top: 32px;
  margin-bottom: 32px;
}

.inputModal-button-text {
}

.inputModal-textfield {
  width: 450px;
  /* height: 52px; */
  left: 661px;
  top: 546px;
  padding-left: 16px;
  border: 0;

  background: #eeeeee;
  border-radius: 8px 0px 0px 8px;
}

.inputModal-button {
  background-color: var(--clr-accent);
  width: 150px;
  border-radius: 0px 8px 8px 0px;
  line-height: 52px;
  border: 0;
}

.inputModal-button:hover {
  opacity: 0.8;
  cursor: pointer;
}

.input-modal-close {
  position: absolute;
  top: 32px;
  right: 32px;
  cursor: pointer;
}

.input-modal-error {
  color: red;
  font-weight: 500;
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translate(-50%, 0);
}

.client-id-dropdown {
  /* height: 70px; */
  /* overflow-y: scroll; */
  color: black;
  border-radius: 8px;
  height: 52px;
  width: 600px;
  margin-top: 12px;
  border: 0;
  background-color: var(--clr-accent);
  color: white;
}