.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}
  
.modal-content {
    width: 75%;
    background: #fff;
    border-radius: 10px;
    padding: 32px 60px;
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    cursor: pointer;
    background: none;
    border: none;
    color: #888888;
    font-weight: 200;
}

.modal-save-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    margin: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6565FF;
    cursor: pointer;
}

.modal-next-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    margin: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6565FF;
    cursor: pointer;
}

.modal-prev-icon {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    margin: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6565FF;
    cursor: pointer;
}

.disableButton{
    opacity: 0.5;
}