.flexRow {
    display: flex;
    flex-direction: row;
}

.flexColumn {
    display: flex;
    flex-direction: column;
}

.overflowAuto {
    overflow: auto;
}

.cursorPointer  {
    cursor: pointer;
}