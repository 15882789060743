.cards-list {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;
}

.clickable {
    cursor: pointer;
}

.card-box {
    padding: 18px;
    background-color: #9898981A;
    min-width: 200px;
    height: 75px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.un-selected-card-box {
    cursor: pointer;
    border: 2px solid transparent; /* Invisible border */
}

/* .un-selected-card-box:hover {
    cursor: pointer;
    background-color: #6565FF1A;
    border: 2px solid #6565FF;
} */

.selected-card-box {
    cursor: pointer;
    background-color: #6565FF1A;
    border: 2px solid #6565FF;
}

/* .selected-card-box:hover {
    cursor: pointer;
    border: 2px solid transparent; 
    transition: border-color 0.3s;
} */

.card-title {
    color: #222222;
    font-size: 14px;
    font-weight: 500;
}

.card-subtitle {
    color: #888888;
    font-size: 10px;
    font-weight: 400;
}

.selected-card-title {
    color: #222222;
    font-size: 14px;
    font-weight: 500;
}

.close-card {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 8px;
    background-color: #FF5353;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    transform: translate(40%, -40%);
    cursor: pointer;
}