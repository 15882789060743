.textTag {
    text-align: center;
    border-radius: 20px;
    width: fit-content;
    padding: 5px 20px 5px 20px;
    font-size: 14px;
    font-weight: 400;
    min-height: 30px;
    margin-right: 5px;
    font-family: DM Sans;
    line-height: 20px;
    color: rgba(34, 34, 34, 1);
}

.textOnlyComponent {
    color: #74828F;
    font-size: 14px;
    font-weight: 500;
}

.tagsList {
    align-items: self-end;
    display: flex;
}

.cursorPointer {
    cursor: pointer;
}

.completeListDisplayDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow: auto;
}

.tableCell {
    padding: 16px 24px 16px 24px;
}

.tagsListModalContent {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.tagsListModalHeader {
    font-size: 16px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 16px;
    font-family: Poppins;
}