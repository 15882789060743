:root {
  --clr-primary: black;
  --clr-secondary: #666;
  --clr-fade: #eee;
  --clr-bg: #fff;
  --clr-accent: #6565ff;
  --clr-dropdown: #494949;
}

.dashboardPage {
  display: flex;
  flex-direction: row;
}

.sidebar {
  width: 100px;
  display: flex;
  text-align: center;
  position: fixed;
  /* background-color: red; */
  z-index: 1;
}

.sidebar:hover {
  width: 290px;
  background-color: white;
  box-shadow: 0px 0px 24px rgba(149, 157, 165, 0.2);
}

.sidebar-margin {
  margin: 72px 24px;
}

.sidebar-logo {
  height: 40px;
  width: 40px;

  margin-bottom: 36px;
}

.sidebar-item {
  height: 40px;
  width: 40px;
  border-radius: 8px;
  margin-bottom: 24px;
  position: relative;
  display: flex;
  background-color: white;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.sidebar-item:hover {
  background-color: var(--clr-fade);
}

.sidebar-item-selected {
  background-color: var(--clr-accent);
}

.sidebar:hover .sidebar-item {
  width: 240px;
  padding: 0 16px;
  justify-content: start;
}

.sidebar-icon {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  height: 24px;
  width: 24px;
}

.divider {
  height: 100vh;
  width: 1px;
  background: rgba(0, 0, 0, 0.2);
}

.sidebar:hover > .divider {
  background-color: white;
}

.sidebar-item-desc {
  display: none;
  font-weight: 500;
  color: #666;
}
.selected-desc {
  color: white;
}

.sidebar:hover .sidebar-item-desc {
  display: block;
  margin: auto 16px;
}

/* DASHBOARD CONTENT */
.dashboard-content {
  margin: 72px 80px 72px 152px;
  flex-grow: 1;
}

.page-title {
  margin-bottom: 64px;
}

.clientID-selector {
  width: 200px;
  height: 48px;
  margin-right: 12px;
  background-color: var(--clr-accent);
  border-radius: 8px;
  position: relative;
  cursor: pointer;
}

.spinner-text {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  margin-left: 24px;
}

/* DROPDOWN */

.dropdown-array {
  display: flex;
}

.custom-dropdown {
  width: 200px;
  height: 48px;
  margin-right: 12px;
  background-color: var(--clr-dropdown);
  border-radius: 8px;
  position: relative;
  border: none;
  text-align: start;
  padding-left: 24px;
}

/* NUMBER CARD */
.number-card-array {
  margin-top: 80px;
  display: flex;
  width: 100%;
  flex: 0 1 auto;
}

.graph-holder {
  height: 500px;
  margin-top: 12px;
  padding: 24px 20px;

  flex-grow: 1;
  position: relative;

  background: white;
  box-shadow: 0px 0px 24px rgba(149, 157, 165, 0.2);
  border-radius: 20px;
}

.pie-graph-holder {
  height: 550px;
  margin-top: 12px;
  padding: 24px 20px;

  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;

  position: relative;

  background: white;
  box-shadow: 0px 0px 24px rgba(149, 157, 165, 0.2);
  border-radius: 20px;
}

.pie-chart-legend {
  text-align: center;
}

.sub-chart-legend {
  display: inline-block;
  margin-right: 20px;
}

.legend-row {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.legend-color {
  height: 12px;
  width: 12px;
  /* background-color: #6565ff; */
  border-radius: 8px;
  margin-right: 8px;
}

.legend-value {
  text-align: center;
}

.row-flex {
  display: flex;
  flex-direction: row;
}

.right-margin24 {
  margin-right: 12px;
}

.center-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.chart-legend {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%);
}

.interval-box {
  width: 220px;
  height: 48px;
  border-radius: 8px;
  border: 2px solid #1d1d1d;
  background: #FFF;  
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  margin-left: auto;
  padding: 24px;
}

.intervalText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #1d1d1d;
  white-space: nowrap;
}

.datePicker {
  position: absolute;
  top: 120%;
  right: 0%;
  z-index: 10;
  border-radius: 8px;
  background: #1d1d1d;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 400px;
}

.datePickerApply{
  height: 50px;
  width: 100px;
  position: absolute;
  top: 464px;
  right: 108px;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  text-align: center;
}

.datePickerCancel{
  height: 50px;
  width: 100px;
  position: absolute;
  top: 464px;
  right: 0px;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  text-align: center;
}

.centerText{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}