.number-card {
  height: 220px;
  padding: 24px 20px;

  flex-grow: 1;
  flex-basis: 0;
  position: relative;

  background: white;
  box-shadow: 0px 0px 24px rgba(149, 157, 165, 0.2);
  border-radius: 20px;
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading-row {
  display: flex;
  align-items: center;
}

.card-icon {
  margin-right: 16px;
}

.card-number {
  font-size: 2.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-right: 24px;
}

.card-subtext {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, 0);
}

.holder {
  height: 500px;
}
