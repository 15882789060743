.loginPage {
  display: flex;
  position: relative;
  z-index: 2;
}

.loginPage-main-logo {
  position: absolute;
  left: 52px;
  top: 60px;
}

.loginPage-img {
  max-height: 100vh;
  width: auto;
  object-fit: cover;
}

.loginPage-right-pane {
  background-color: white;
  flex-grow: 1;
  margin: 0 auto;
  text-align: center;
  position: relative;
}

.loginPage-button {
  display: flex;
  justify-content: center;
  position: relative;
  margin: 2.4rem auto;
}

.custom-loginPage-button {
  height: 48px;
  width: 240px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 2.4rem auto;
  background-color: #6565FF;
}

.buttonLogo {
  height: 16;
  width: 16;
  margin: auto 0;
  margin-right: 12px;

}

.center-vertically {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
}
