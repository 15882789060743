table.style1 {
    width: 100%;
}
thead.style1 {
    height: 45px;
    width: 80%;
}
th.style1 {
    padding: 6px 24px;
    background-color: #6565FF1A;
}
th.style1 div.style1 {
    font-weight: 600;
    font-size: 14px;
    color: #464646;
}
tr.style1 {
    height: 64px;
    border-bottom: 1px solid #DBDBDB;
}
tr.style1 td.highlightOnHover:hover {
    background-color: #6565FF1A;
}
tfoot.style1 tr.style1 {
    height: 64px;
    border-bottom: 1px solid #DBDBDB;
}