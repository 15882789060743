.billingPage {
  padding-left: 152px;
  padding-top: 72px;
  padding-right: 50px;
  padding-bottom: 50px;
}

.header-margin {
  margin-bottom: 52px;
}

.bold-span{
  font-weight: 500;
}

.pageHeaders {
  font-size: 16px;
  color: #222222;
  font-weight: 600;
  margin-bottom: 24px;
}

.pageSubHeaders {
  margin-top: 32px;
  font-size: 12px;
  font-weight: 500;
}

.legendPickerFont {
  font-size: 12px;
  font-weight: 500;
  align-self: center;
  margin: 0 auto;
}

.glanceCardsRow {
  display: flex;
  gap: 8px;
  margin-bottom: 52px;
}

.glanceCard {
  position: relative;
  background-color: #e5e5ff;
  border-radius: 12px;
  height: 200px;
  flex-grow: 1;
}


.cardDatePicker{
  position: absolute;
  right: 20px;
  top: 20px;
  height: 20px;
  cursor: pointer;
}

.glanceCardTitle {
  position: absolute;
  font-size: 52px;
  font-weight: 200;

  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
}

.glanceCardSubTitle {
  position: absolute;
  font-size: 14px;
  font-weight: 300;
  white-space: nowrap;

  top: 65%;
  left: 50%;
  transform: translate(-50%, 0);
}

.graphBox {
  /* height: 334px; */
  background-color: #f9f9ff;
  padding: 0 32px;
  border-radius: 20px;
  margin-bottom: 52px;
  display: flex;
}

.graphLegend {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.legendTitle {
  align-self: center;
  font-size: 12px;
  margin: 0;
  width: 100px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.legendSolidLine {
  width: 32px;
  height: 4px;
  background-color: #df9d90;
  margin-right: 12px;
}

.legendDottedLine {
  width: 32px;
  height: 4px;
  margin-right: 12px;
  border: 2px dashed #df9d90;
}

.usageTrendsGraph {
  height: 500px;
  width: 100%;
}

.monthPicker {
  display: flex;
  justify-content: start;
  /* width: 100px; */
}

.graphInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 32px;
}

.centerVertically {
  align-self: center;
}

.externalLink {
  padding-top: 52px;
  padding-right: 150px;
  margin-top: 52px;
  font-size: 14px;
  color: blue;
  /* background-color: red; */
  margin: 0 auto;
}

.datePicker2 {
  position: absolute;
  top: 240px;
  left: 0;
  z-index: 10;
  border-radius: 8px;
  background: #1d1d1d;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 400px;
}

.datePickerApply2 {
  height: 50px;
  width: 100px;
  position: absolute;
  top: 650px;
  left: 108px;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  background-color: white;
}

.datePickerCancel2 {
  height: 50px;
  width: 100px;
  position: absolute;
  top: 650px;
  left: 0px;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  background-color: white;
}

.centerText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}